import { ProcessStates } from '../system/types';

export enum CheckoutErrors {
  InvalidStep = 'CHK001',
  TokenMissing = 'CHK002',
  UnknownProvider = 'CHK003',
  RequestAlreadyFailed = 'CHK004',
  RequestAlreadyCanceled = 'CHK005',
}

export enum DisplayMode {
  Redirect = 'r',
  Frame = 'f',
  Widget = 'w',
}

export enum ProcessingPaymentMethodType {
  Card = 'card',
  Blockchain = 'blockchain',
  Bank = 'bank',
  UPI = 'upi',
  None = '',
}

export enum StepMode {
  Terms = 'TERMS',
  NewCustomer = 'NEWCS',
  BillingData = 'BILL',
  Identification = 'IDENT',
  PayMethod = 'PAYMET',
  Wait = 'WAIT',
  Logon = 'LOGON',
  Email = 'EMAIL',
  ShowWallet = 'WALLET',
  FinOperation = 'FINOPER',
  CountryOfRes = 'COR',
}

export interface IProcessingStep {
  requestId: string;
  test: boolean;
  auto: boolean;
  displayMode: DisplayMode;
  finalStep: boolean;
  language: string;
  resultCode: string;
  resultMessage: string;
  redirect: string;
  apiToken: string;
  apiUrl: string;
  email: string;
  phone: string;
  uid: string;
  processingStepCode: string;
  processingStepFrom: number;
  processingStepMode: StepMode;
  processingStepObject: string;
  processingStepObjectType: string;
  processingStepOrder: number;
  processingStepSettings: string;
  processingStepState: ProcessStates;
  processingStepTill: number;
  processingStepType: string;
  processingStepData: string | null;
  waitData: IWaitData | null
}

export interface IProcessingRequest {
  requestId: string
  requestCurrency: string
  requestCurrencyProcess: string
  requestTerminalBrandName: string
  requestAmount: number
  requestAmountProcess: number
  declineCallback: string
  cancelCallback: string
  successCallback: string
  receiverWalletNumber: string
  receiverWalletNetwork: string
  requestPayMethodType: ProcessingPaymentMethodType
  requestDisplayMode: DisplayMode
  requestState: ProcessStates
  requestCreated: Date
  requestExpired?: Date
  requestResultMessage: string
}

export interface IBrowserData {
  acceptHeaders: string
  javaEnabled: boolean
  language: string
  colorDepth: number
  screenWidth: number
  screenHeight: number
  timeZoneOffsetMin: number
  userAgent: string
}

export interface IBillingData {
  firstName: string
  lastName: string
  email: string
  phone: string
  birthday: Date | null
  cor: string
  street: string
  city: string
  state: string
  country: string
  zip: string
  docType: string | null
  docNumber: string
  docIssued: Date | null
  docExpired: Date | null
}

export interface IBillSettings {
  name: boolean
  email: boolean
  phone: boolean
  cor: boolean
  address: boolean
  birthday: boolean
  document: boolean
}

export interface IProcessingPayMethod {
  type: ProcessingPaymentMethodType
  token: string | null
  url: string | null
  number: string
  issuer: string | null
  branch: string | null
  owner: string | null
}

export interface IWaitData {
  waitTill: number
  wallet: string
  delay: number
  delayTill: number
  waitStyle: string
}
