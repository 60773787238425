import { IMultifactor, IOTP, User2FAModes } from '../session/types';
import { EntityStates, IDateRange, IFilter, INumberRange, ProcessStates, ProcessingMethods } from '../system/types';

export const ROW_NUMBER_START_BAL = -97;
export const ROW_NUMBER_TURNS = -98;
export const ROW_NUMBER_END_BAL = -99;

export enum POSMode {
  Deposit = 'deposit',
  Link = 'link',
  Payout = 'payout',
}

export interface ICompanyData {
  companyId: number;
  companyName: string;
  companyCode: string;
  brandName: string;
  supportEmail: string;
  supportPhone: string;
}

export enum DiscretizationTypes {
  Hour = 'hour',
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Quarter = 'quart',
  Year = 'year',
  All = 'all',
}

export enum Process2fa {
  Check = 'check',
  OTP = 'otp',
  Init = 'init',
  Change = 'change',
  Required = 'required',
}

export enum Urgency {
  Regular = 'R',
  Urgent = 'U',
}

export enum OperTypeModes {
  Buy = 'BUY',
  Sell = 'SLL',
  Send = 'SND',
  Get = 'GET',
  Topup = 'TOP',
  Withdraw = 'WTH',
  Hold = 'HLD',
  Transfer = 'TRF',
  TransferIn = 'TRI',
  TransferOut = 'TRO',
  Any = 'ANY',
}

export interface IChange2FA {
  mode: User2FAModes | null;
  multifactor: IMultifactor | null;
  secureCode: string;
}

export interface IRate {
  currBuy: string;
  currSell: string;
  gateCode: string | null;
  realRate: number;
  customerRate: number;
}

export interface ITerminalCurrency {
  terminalId: number | null;
  currencyCode: string | null;
  terminalCurrencyState: EntityStates;
  terminalAccount: string;
}

export interface ITerminalProcessingType {
  processingTypeCode: string
  processingTypeIsLive: boolean
}

export interface IBrandTerminal {
  terminalId: number | null;
  brandId: number | null;
  terminalNumber: string;
  terminalName: string;
  terminalState: EntityStates;
  terminalSecret: string;
  terminalCheckouts: boolean;
  terminalRefunds: boolean;
  terminalPayouts: boolean;
  terminalToken: boolean;
  processingTypeCode: string;
  processingCurrency: string;
  currencies: Array<ITerminalCurrency>;
  brandNumber?: string;
  brandName?: string;
  customerNumber?: string;
  customerName?: string;
  showkey?: boolean;
  editing?: boolean;
  color?: string;
  processing: ITerminalProcessingType;
}

export interface ICustomerBrand {
  brandId: number | null;
  customerId: number;
  customerNumber: string;
  brandNumber: string;
  brandName: string;
  brandState: EntityStates;
  brandFeeGroup: string;
  brandSecuredIP: string;
  terminals: Array<IBrandTerminal>;
  objectCreated: Date | null;
  objectCreator: string | null;
  objectEdited: Date | null;
  objectEditor: string | null;
}

export interface IAddress {
  addressRecepient: string | null;
  addressCountry: string | null;
  addressState: string | null;
  addressCity: string | null;
  addressStreet: string | null;
  addressHouse: string | null;
  addressHousing: string | null;
  addressRoom: string | null;
  addressIndex: string | null;
}

export enum DocumentType {
  IDCard = 'ID',
  Passport = 'PS',
  UtilityBill = 'UT',
  MoneySource = 'MS',
  Other = 'OT',
}

export interface IDocument {
  documentId: number | null;
  customerId: number;
  personId: number | null;
  personName: string | null;
  documentType: DocumentType;
  documentCountry: string;
  documentNumber: string;
  documentIssued: Date | null;
  documentExpired: Date | null;
  documentAuthor: string | null;
  documentNotes: string | null;
  default: boolean;
  active: boolean;
  objectCreated: Date | null;
  objectCreator: string | null;
}

export enum CustomerState {
  Active = 'a',
  Closed = 'x',
  Blocked = 'b',
}

export enum CustomerType {
  Private = 'PRV',
  Limited = 'LTD',
}

export interface ICustomer {
  customerId: number | null;
  customerNumber: string;
  customerNumberPublic: string;
  customerType: CustomerType;
  customerDepartment: string;
  customerName: string;
  customerLegalName: string;
  customerRegisterDate: Date,
  customerIndustryCode: string;
  customerTaxNum: string;
  customerRegisterNum: string;
  customerLanguage: string;
  customerEmail: string;
  customerCountry: string;
  customerState: CustomerState;
  customerAddress: IAddress;
  customerDocuments: Array<IDocument>;
  brands: Array<ICustomerBrand>;
  customerFeeGroup: string;
  objectCreated: Date | null;
  objectCreator: string | null;
}

export enum CurrencyType {
  Fiat = 'F',
  Crypto = 'C',
  Token = 'T',
}

export interface ICurrency {
  currencyCode: string;
  currencyName: string;
  currencyGate: string | null;
  productCode: string;
  currencyPrecision: number;
  currencyType: CurrencyType;
  currencyState: EntityStates;
  baseRate: IRate;
  currencyIcon: string | null;
  currencyOrder: number;
}

export interface INetworkAddress {
  accountId?: number;
  networkId: string;
  networkAddress: string;
  networkAssetNumber?: string;
  accountNumber: string;
  networkName?: string;
  networkGate?: string;
  networkOmnibusNotes?: string | null;
  networkBaseCurrency?: string;
  networkNoteName?: string;
  privateKey?: string;
  balance: number;
}

export enum AccountStates {
  Active = 'a',
  Debet = 'd',
  Credit = 'c',
  Blocked = 'b',
  Closed = 'x',
}

export enum AccountTypeModes {
  Acquirer = 'AQ',
  Bank = 'BN',
  CustomerCurrent = 'CA',
  CusomerDebt = 'CD',
  CustomerProcessing = 'CP',
  CusomerReserve = 'CR',
  CusomerTransit = 'CT',
  Position = 'EP',
  Loss = 'LS',
  Profit = 'PR',
  Reservations = 'RS',
  Correspondent = 'SP',
  Stock = 'ST',
  SystemTransit = 'TR',
}

export interface IOpenAccountRequest {
  accountCurrency: string;
  accountTypeCode: string;
  productCode: string;
}

export interface IAccount extends IOpenAccountRequest {
  accountId?: number;
  accountNumber: string | null;
  accountBookNumber: string;
  accountNumberPublic: string;
  customerNumber: string;
  customerNumberPublic: string;
  accountName: string;
  accountOpened: Date;
  accountState: AccountStates;
  accountTypeMode: AccountTypeModes;
  accountGate: string | null;
  accountBalFin: number;
  accountBalHold: number;
  accountBalAvail: number;
  accountBalAvailEq: number;
  accountBalMin: number | null;
  accountBalMax: number | null;
  accountTurnDayMaxIn: number | null;
  accountTurnMonthMaxIn: number | null;
  gateCode: string | null;
  currency: ICurrency;
  customer?: ICustomer;
  manageByCustomer: boolean;
  networks: Array<INetworkAddress>;
}

export interface INetworkSettings {
  networkId: string;
  networkName: string;
  networkGate: string;
  currencyCode: string;
  networkBaseCurrency: string;
  networkNoteName: string;
}

export enum PersonState {
  Active = 'a',
  Closed = 'x',
  TempLock = 't',
  Blocked = 'b',
}

export interface IPerson {
  personId: number | null;
  personState: PersonState;
  personNumber: string | null;
  personFirstName: string;
  personLastName: string;
  personEmail: string;
  personPhone: string;
  personEmailVerified: boolean;
  personPhoneVerified: boolean;
  personConcent: boolean;
  personBirthDay: Date;
  personCode: string;
  personCountry: string;
  personIdentLevel: number;
  personIdentLevelNext: number;
  document: IDocument;
  objectCreated: Date | null;
  objectCreator: String | null;
}

export enum FeeClass {
  Fee = 'FEE',
}

export interface IFee {
  feeId: number | null;
  feeClass: FeeClass;
  feeCode: string;
  feeGroup: string;
  feeCurr: string;
  feeAmount: number;
  feePercents: number;
  feeFixed: number;
  feeInfo: string | null;
  operFeeBase: number;
  currency: ICurrency;
  feeAccount: string | null;
}

export enum OperTypePrecheckMode {
  None = 'NO',
  DebetAmount = 'DT',
  DebetAmountAndFee = 'DF',
}

export enum PaymentMethodType {
  InternalAccount = 'account',
  Blockchain = 'blockchain',
  Card = 'card',
  Bank = 'bank',
  Alternative = 'apm',
}

export enum ProcessingPaymentMethodType {
  Card = 'card',
  Blockchain = 'blockchain',
  Bank = 'bank',
  UPI = 'upi',
  None = '',
}

export interface IOperModeSettings {
  operMode: OperTypeModes;
  accountTypeCode: string;
  feeCode: string;
  operCurrencyBuy: string;
  operCurrencySell: string;
  customerCountry: string;
  userProfileType: string;
  payMethodTypeVariant: string;
  operTypeCode: string;
  operInfoTitle: string | null;
  operInfoValue: string | null;
  order: number
}

export interface IOperType {
  operTypeCode: string;
  operTypeName: string | null;
  operTypeIcon: string | null;
  operTypeColor: string | null;
  operTypeState: EntityStates | null;
  operTypeMode: string | null;
  operType2FA: boolean;
  operTypePrecheck: OperTypePrecheckMode;
}

export interface IOperTypeSettings extends IOperType {
  operTypeRuleId: string;
  feeCode: string;
  operCurrencyBuy: string;
  operCurrencySell: string;
  payMethodTypeVariant: string;
  payMethodCountry: string;
  operMinMaxEqiv: boolean;
  operMinAmount: number;
  operMaxAmount: number;
  operUserCrossRate: boolean;
  operDefaultGate: string | null;
  operInfoTitle: string | null;
  operInfoValue: string | null;
}

export interface IPayMethodType {
  payMethodTypeVariant: string;
  payMethodType: PaymentMethodType;
  payMethodTypeName: string;
  payMethodFormUI: string;
  payMethodMultiCurrency: boolean;
  payMethodTypeIcon: string;
  payMethodMemoName: string;
}

export interface IPayMethodSettings {
  payMethodSettingId: string;
  operTypeMode: OperTypeModes;
  operCurrencyBuy: string;
  operCurrencySell: string;
  payMethodFeeCode: string;
  userProfileType: string;
  payMethodTypeVariant: string;
  payMethodType: PaymentMethodType;
  payMethodStatic: boolean;
  payMethodGate: string;
  payMethodChannel: string;
  payMethodAppendable: boolean
  payMethodSettingDefault: boolean
}

export interface IPayMethod {
  userPayMethodId: string | null;
  payMethodGlobal: boolean;
  payMethodNumber: string;
  payMethodTypeVariant: string;
  payMethodType: PaymentMethodType;
  payMethodName: string;
  payMethodExpired: Date;
  payMethodCountry: string;
  payMethodToken: string;
  payMethodNetwork: string;
  payMethodIssuerCode: string;
  payMethodIssuerName: string;
  payMethodReceiverName: string;
  payMethodReceiverAddress: string;
  payMethodMemo: string;
  payMethodFavoriteBuy: boolean;
  payMethodFavoriteSell: boolean;
  payMethodFavoriteSend: boolean;
  payMethodFavoriteGet: boolean;
  payMethodFavoriteTopup: boolean;
  payMethodFavoriteWithdraw: boolean;
  payMethodState: ProcessStates;
  payMethodFee: string;
  payMethodFeeMin: string;
  payMethodIcon: string | null;
  payMethodInfo: string;
  payMethodCurrency: string;
  type: IPayMethodType;
  removing: boolean;
  processing: boolean;
}

export interface Replace<T> {
  current: T
  updated: T
}

export interface IOperation {
  frontId: number;
  operTypeCode: string;
  operNumber: string | null;
  operMainCurrency: string;
  operValueDate: Date;
  operBookDate: Date;
  productCode: string | null;
  operOutAccount: string | null;
  operOutCurrency: string;
  operOutAmount: number;
  operOutCustomer: string | null;
  operRate: number;
  operInAccount: string | null;
  operInWallet: string | null;
  operInCurrency: string;
  operInAmount: number;
  operInCustomer: string | null;
  operDescr: string;
  operFeeCurrency: string;
  operFeeAmount: number;
  operBaseFeeAmount: number;
  operUrgency: Urgency;
  operGate: string | null;
  operGateChannel: string | null;
  operationGateRef: string | null;
  operGateIn: string | null;
  operGateInChannel: string | null;
  operationGateInRef: string | null;
  operGateOut: string | null;
  operGateOutChannel: string | null;
  operationGateOutRef: string | null;
  operOtpCheck: IOTP | null;
  operToken: string | null;
  payMethod: IPayMethod | null;
  operationCountry: string | null;
  feeGroup: string | null;
  operPerson: string | null;
  objectCreated: Date | null;
  operState: ProcessStates | null;
  operMode: OperTypeModes;
  operBrowserData: string | null;
  waitRedirect: boolean;
  operInAccountPublic?: string | null;
  operOutAccountPublic?: string | null;
  operFeeAmountPublic?: string;
  forAccountType?: string;
  fees: Array<IFee>;
}

export interface IOperationFull extends IOperation {
  operationId: number | undefined;
  operReference: string;
  operResultCode: string | undefined;
  operResultDetails: string | undefined;
  operRejectCode: string | undefined;
  operRejectDetails: string | undefined;
  operOutWallet: string | undefined;
  operPackRef: string | undefined;
  operAttachmentRef: string | undefined;
  redirectUrl: string | undefined;
  operInAmountPublic: string;
  operOutAmountPublic: string;
  outCurrency: ICurrency;
  inCurrency: ICurrency;
  feeCurrency: ICurrency;
  inAccount: IAccount;
  outAccount: IAccount;
  inCustomer: ICustomer;
  outCustomer: ICustomer;
  person: IPerson;
}

export interface ITransFilter {
  from: string;
  till: string;
  page: number;
  limit: number;
}

export enum RedirectModes {
  None = 'N',
  Frame = 'F',
  Widget = 'W',
  Redirect = 'R',
}

export interface IIdentLevel {
  identLevelId: number;
  identLevelState: EntityStates;
  identLevelDayIn: number;
  identLevelMonthIn: number;
  identLevelDayOut: number;
  identLevelMonthOut: number;
  identLevelRedirectMode: RedirectModes;
  identLevelComplete: boolean;
  personLevelState: ProcessStates;
  personLevelId: string | null;
  personLevelUrl: string | null;
  personLevelToken: string | null;
  personLevelProvider: string | null;
  customerTurnDayIn: number;
  customerTurnMonthIn: number;
  customerTurnDayOut: number;
  customerTurnMonthOut: number;
  customerTopupAllowed: number;
  customerWithdrawAllowed: number;
  hasFiatTopup: boolean;
  hasFiatWithdraw: boolean;
  hasCryptoGet: boolean;
  hasCryptoSend: boolean;
  hasFiatExchange: boolean;
  hasCryptoExchange: boolean;
}

export interface IVerificationIdent {
  level: number;
  docType: DocumentType;
  docCountry: string | null;
  customerCountry: string | null;
  docFrontAttach: string | null;
  docBackAttach: string | null;
  utilityAttach: string | null;
  moneyAttach: string | null;
}

export interface IVerification {
  verificationId: string;
  verificationType: string;
  verificationProvider: string;
  verificationState: ProcessStates;
  verificationStarted: Date;
  verificationExpired: Date;
  verificationComplete: Date;
  verificationURL: string;
  verificationData: string;
  verificationObject: string;
}

export interface IRatesHistory {
  buy: string;
  sell: string;
  rates: Array<number>;
  priceChange: number;
}

export interface IDashboardItemChangeName {
  dashboardItemName: string;
}

export interface IDashboardItemChangeFavorite {
  dashboardItemFavorite: boolean;
}

export interface IDashboardItemChangeData extends IDashboardItemChangeFavorite, IDashboardItemChangeName {
  dashboardItemId?: string;
}

export interface IDashboardItem extends IDashboardItemChangeData {
  userId: number;
  currencyBase: string;
  currencyCode: string;
  accountNumber: string;
  accountNumberPublic: string;
  accountName: string;
  dashboardItemOrder: number;
  balance: number;
  realRate: number;
  customerRate: number;
  currency: ICurrency;
  account: IAccount | null;
  history: IRatesHistory | null;
}

export interface IDashboardItemChangeHistory {
  history: IRatesHistory;
}

export interface IDashboardItemChangeAccount {
  account: IAccount;
}

export interface IDashboardItemChangeRequest {
  item: IDashboardItem;
  data: IDashboardItemChangeHistory | IDashboardItemChangeAccount | IDashboardItemChangeFavorite | IDashboardItemChangeName | IDashboardItemChangeData
}

export interface IRateRequest {
  currBuy: string;
  currSell: string;
  currMain: string;
  operType: string;
  operMode: OperTypeModes;
  useCrossRate: boolean;
  gate: string | null;
}

export interface ICardToken {
  cardTokenNumber: string;
  widgetUrl: string;
  cardNumber?: string
  routingState?: string
}

export interface IProcessingRequestBase {
  requestId?: string
  requestMethod: ProcessingMethods
  requestTestMode: boolean
  requestState: ProcessStates
  requestTerminal: string
  requestTracking?: string
  requestAmount: number
  requestCurrency: string
  requestDescription: string
  requestReason?: string
  requestCountry?: string
  requestPayMethodType?: ProcessingPaymentMethodType
  requestPayMethodToken?: string | null
  requestCustomerFirstName?: string
  requestCustomerLastName?: string
  requestCustomerCountry?: string
  requestCustomerEmail?: string
  requestCustomerPhone?: string
  requestCustomerAdrCity?: string
  requestCustomerAdrCountry?: string
  requestCustomerAdrLine1?: string
  requestCustomerAdrLine2?: string
  requestCustomerAdrZip?: string
  requestCustomerAdrState?: string
  requestCustomerDocNumber?: string
  requestDisplayMode?: string
  requestDisplayLanguage?: string
  requestBrowserData?: string
  requestResultCode?: string
  requestResultMessage?: string
  requestOriginalId?: string
}

export interface IProcessingRequest extends IProcessingRequestBase {
  requestIdShort?: string
  requestVersion?: string
  requestOriginalId?: string
  requestRouteChannel?: string
  requestRouteGate?: string
  requestRouteOperRef?: string
  requestTerminalOwner: string;
  requestProcessingType: string
  requestServerIP?: string
  requestUserIP?: string
  requestExpired?: string
  requestAmountRefunded: number
  requestDispute?: boolean
  requestCustomerId?: string
  requestCustomerNumber?: string
  requestAccDeposit?: string
  requestAccDest?: string
  requestAccMixer?: string
  requestLastStep?: string
  requestGroupState?: string
  requestGroupOperRef?: string
  requestGroupId?: string
  requestAmountProcess?: number
  requestFeeProcess?: number
  requestCurrencyProcess: string
  requestRate: number
  objectCreated?: Date
  objectCreator?: string
  objectEdited?: Date
  objectEditor?: string
  customer?: ICustomer
  currency: ICurrency
  processingCurrency: ICurrency
}

export interface ITransaction {
  rowNumber: number;
  tranId: number;
  tranCallerRef: string | null;
  transDocNumber: string;
  tranReference: string;
  transDate: Date;
  tranAccountDtNumber: string;
  tranAccountDtPublic: string;
  tranAccountCtNumber: string;
  tranAccountCtPublic: string;
  tranCorrName: string;
  tranCorrCustomer: string;
  tranDtCurrency: string;
  tranDtAmount: number;
  tranTurnDt: number;
  tranTurnCt: number;
  tranBalance: number;
  tranDescr: string;
  transCorrType: null | 'CT' | 'DT';
  transTemplateCode: string;
  transLinkedGate: string | null;
  tranState: ProcessStates;
  currency: ICurrency;
}

export interface IStatement {
  accountNumber: string;
  accountNumberPublic: string;
  accountName: string;
  accountCurrency: string;
  statementFrom: Date;
  statementTo: Date;
  startBalance: number;
  turnoverDt: number;
  turnoverCt: number;
  endBalance: number;
  transactions: Array<ITransaction>
  currency: ICurrency;
}

export interface IBalance {
  accountNumber: string | null;
  accountNumberPublic: string;
  customerNumber: string;
  customerNumberPublic: string;
  accountName: string;
  accountOpened: Date;
  balanceCurrency: string;
  balanceFrom: Date;
  balanceTo: Date;
  balanceFin: number;
  balanceHold: number;
  balanceTurn: number;
  currency: ICurrency;
}

export enum StatementDateType {
  Real = 'r',
  Booking = 'b',
  Settlement = 's',
}

export interface IOperFilter extends IFilter {
  operReference: string | null;
  accountNumber: string | null;
  customerNumber: string | null;
  processState: string[] | null;
  operTypeCode: string | null;
  operCurrency: string[] | null;
  amount: INumberRange;
  objectCreated: IDateRange;
}

export interface IStatementFilter extends IFilter {
  accountNumber: string | null;
  bookingNumber: string | null;
  bookCurrency: string | null;
  dateType: StatementDateType;
  range: IDateRange;
  hideStorno: boolean;
}

export interface IProcessingFilter extends IFilter {
  requestId: string | null;
  requestCurrency: string[] | null;
  requestMethod: string | null;
  requestState: string[] | null;
  requestProcessingType: string | null;
  requestTerminal: string | null;
  requestTestMode: boolean;
  customerNumber: string | null;
  dateType: StatementDateType;
  amount: INumberRange;
  objectCreated: IDateRange;
}

export interface IDataPreview {
  visible: boolean
  operation?: IOperationFull
  transaction?: ITransaction
  request?: IProcessingRequest
  callback?: any
}

export interface Month {
  key: string
  name: string
  from: Date
  till: Date
}

export interface ICustomerQuestionnaireQuestion {
  customerQuestValueId: number
  customerQuestionScore: number
  customerQuestionValue: any
  customerQuestionnaireId: number
  questSectionId: string
  questionDefScore: number
  questionDescr: string
  questionFormat: string
  questionId: string
  questionOrder: number
  questionRequired: boolean
  questionState: EntityStates
  questionText: string
  questionType: string
  questionnaireCode: string
  visible: boolean
  enabled: boolean
}

export interface ICustomerQuestionnaireSection {
  questSectionDescr: string
  questSectionId: string
  questSectionName: string
  questSectionOrder: number
  questionnaireCode: string
  visible: boolean
  enabled: boolean
  questions: Array<ICustomerQuestionnaireQuestion>
}

export interface ICustomerQuestionnaireActionCondition {
  questActionId: string
  questConditionId: string
  questConditionOperator: string
  questConditionSideA: string
  questConditionSideB: string
}

export interface ICustomerQuestionnaireAction {
  questActionId: string
  questActionMethod: string
  questActionMode: string
  questSectionId: string | null
  questionId: string | null
  questionnaireCode: string
  conditions: Array<ICustomerQuestionnaireActionCondition>
}

export interface ICustomerQuestionnaireState {
  customerQuestionnaireId: number | null;
  customerQuestionnaireState: ProcessStates;
  customerNumber: string;
}

export interface ICustomerQuestionnaire extends ICustomerQuestionnaireState {
  customerId: number;
  personId: number;
  personName: string;
  customerQuestionnaireVersion: number;
  customerQuestionnaireScore: number | null;
  questionnaireWithAuth: boolean;
  questionnaireCode: string;
  questionnaireName: string;
  questionnaireDescr: string | null;
  questionnaireType: string;
  questionnaireState: EntityStates;
  objectCreated: Date | null;
  objectCreator: string | null;
  objectEdited: Date | null;
  objectEditor: string | null;
  oldVersions: Array<ICustomerQuestionnaire>
  sections: Array<ICustomerQuestionnaireSection>
  actions: Array<ICustomerQuestionnaireAction>
}

export interface IReport {
  reportId: string;
  reportName: string;
  reportType: string;
  reportFormat: string;
  reportCompressed: string;
  reportLanguage: string;
  reportState: ProcessStates;
  reportCreated: Date;
  reportForUser: string;
  reportAttachment: string;
  reportDetails: string;
}

export interface IReportFilter extends IFilter {
  reportType: string | null;
  reportFormat: string | null;
  reportState: string | null;
  reportCreated: IDateRange;
}
