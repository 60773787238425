import $ph from '@/plugins/phoenix';
import { AppError } from '@/plugins/phoenix/library';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { IRegistration } from '../session/types';
import { ICountry } from '../system/types';
import { CheckoutErrors, IBrowserData, IProcessingRequest, IProcessingStep, IWaitData } from './types';

@Module({ name: 'checkout' })
export default class CheckoutStore extends VuexModule {
  private _step: IProcessingStep | null = null
  private _request: IProcessingRequest | null = null;
  private _error: AppError | null = null
  private _node: string = '000'
  private _ref: string = ''

  // ---------------------------------------------------------------------- GETTERS
  get node(): string {
    return this._node
  }

  get step(): IProcessingStep | null {
    return this._step
  }

  get request(): IProcessingRequest | null {
    return this._request
  }

  get checkoutError(): AppError | null {
    return this._error
  }

  // ---------------------------------------------------------------------- MUTATIONS
  @Mutation
  setRef(value: string) {
    this._ref = value;
  }

  @Mutation
  setNode(value: string) {
    this._node = value;
  }

  @Mutation
  setStep(value: IProcessingStep) {
    value.waitData = null
    try {
      if (value.processingStepData && value.processingStepData.trim() !== '') {
        value.waitData = JSON.parse(value.processingStepData || '')
      }
    } catch (ignore) {
    }
    this._step = value
  }

  @Mutation
  setRequest(value: IProcessingRequest) {
    this._request = value;
  }

  @Mutation
  setCheckoutError(value: any) {
    let error: AppError | null = null

    if (value) {
      let msg = ''

      if (value.message) {
        msg = value.message
      } else if (value.errorText) {
        msg = value.errorText
      }

      let details = ''

      if (value.details) {
        details = value.details
      } else if (value.errorText) {
        details = value.errorDetails
      }

      let code = ''

      if (value.errorCode) {
        code = value.errorCode
      } else if (value.response) {
        code = 'HTTP' + value.response.status
      } else {
        code = 'SYS001'
      }

      let errKey = 'system.errors.' + code
      let errMsgLocal = $ph.i18n(errKey)

      if (errKey !== errMsgLocal) {
        msg = errMsgLocal
      }

      error = new AppError(code, msg, details, false)

      $ph.error(error)
    }

    this._error = error;
  }

  // ---------------------------------------------------------------------- ACTIONS
  @Action({ rawError: true })
  async prepareToken(token: string) {
    const node = !token || token.trim() === '' ? '000' : token.substring(0, 3)
    const ref = !token || token.trim() === '' ? 'NO-TOKEN' : token.substring(4, token.length)
    this.setNode(node)
    this.setRef(ref)
    const rq = await $ph.get('/requests/' + node + '/' + ref + '/info')
    this.setRequest(rq)
  }

  @Action({ rawError: true })
  async nextStep() {
    const response = await $ph.get('/requests/' + this._node + '/' + this._ref)
    if (response.requestId === this._node + '-' + this._ref) {
      this.setStep(response);
    } else {
      throw new AppError(CheckoutErrors.InvalidStep, 'Invalid step response!')
    }
  }

  @Action({ rawError: true })
  async refreshRequest() {
    const rq = await $ph.get('/requests/' + this._node + '/' + this._ref + '/info')
    this.setRequest(rq)
  }

  @Action({ rawError: true })
  async getAllCountries(): Promise<Array<ICountry>> {
    return $ph.get('/requests/' + this._node + '/' + this._ref + '/countries')
  }

  @Action({ rawError: true })
  async cancel() {
    await $ph.post('/requests/' + this._node + '/' + this._ref + '/cancel', {})
  }

  @Action({ rawError: true })
  async updateBrowserData(data: IBrowserData) {
    await $ph.post('/requests/' + this._node + '/' + this._ref + '/browser', data)
  }

  @Action({ rawError: true })
  async updatePageLog(log: string) {
    await $ph.post('/requests/' + this._node + '/' + this._ref + '/log', log, {}, 'text/plain')
  }

  @Action({ rawError: true })
  async procesStep() {
    const response = await $ph.patch('/requests/' + this._node + '/' + this._ref, {})
    if (response.requestId === this._node + '-' + this._ref) {
      this.setStep(response);
    } else {
      throw new AppError(CheckoutErrors.InvalidStep, 'Invalid step response!')
    }
  }

  @Action({ rawError: true })
  async updateStep(value: string) {
    const response = await $ph.patch('/requests/' + this._node + '/' + this._ref, value, {}, 'text/plain')
    if (response.requestId === this._node + '-' + this._ref) {
      this.setStep(response);
    } else {
      throw new AppError(CheckoutErrors.InvalidStep, 'Invalid step response!')
    }
  }
}

export function hasText(value: any): boolean {
  return Boolean(value) && String(value) !== '';
}

export function validEmail(value: any): boolean {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(value)
}
